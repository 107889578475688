<template>
  <div>
    <h1>Lançamentos de ajuste para {{ month }}/{{ year }}</h1>
    <b-card no-body class="mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontradas comissões para esta busca"
        :busy="loading"
        :no-local-sorting="true"
      >
        <template #cell(id)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.id }}</span>
          </div>
        </template>
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.consultant_name }}</span>
          </div>
        </template>
        <template #cell(name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.name }}</span>
          </div>
        </template>
        <template #cell(credit_type)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.credit_type }}</span>
          </div>
        </template>
        <template #cell(value)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.value | toCurrency
            }}</span>
          </div>
        </template>
        <template #cell(launch_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.launch_date | onlyDate
            }}</span>
          </div>
        </template>
        <template #cell(billing_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.billing_date | onlyDate
            }}</span>
          </div>
        </template>
        <template #cell(comment)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.comment }}</span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              :value="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import vSelect from "vue-select";
import * as types from "@/modules/financial_adjustment/store/types";
import * as sharedTypes from "@/modules/shared/store/types";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "id", label: "ID", sortable: false },
        { key: "consultant_name", label: "Nome", sortable: false },
        { key: "name", label: "Descrição do lançamento", sortable: false },
        { key: "credit_type", label: "Tipo", sortable: false },
        { key: "value", label: "Valor", sortable: false },
        { key: "launch_date", label: "Data de lançamento", sortable: false },
        { key: "billing_date", label: "Data de faturamento", sortable: false },
        { key: "comment", label: "Observações", sortable: false },
      ],
      currentPage: 1,
      itemsPerPage: 3,
    };
  },
  computed: {
    ...mapGetters({
      result: types.FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    month() {
      return (this.$route.params?.month || 0).toString().padStart(2, "0");
    },
    year() {
      return this.$route.params.year || 0;
    },
    items() {
      return this.result?.data || [];
    },
    totalItems: function () {
      return this.result?.total || 0;
    },
    pageItemsFrom: function () {
      return this.result?.from || 0;
    },
    pageItemsTo: function () {
      return this.result?.to || 0;
    },
  },
  setup() {
    return { toast: useToast() };
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search(this.currentPage);
  },
  methods: {
    ...mapActions({
      getAdjustmentsByMonth: types.GET_FINANCIAL_ADJUSTMENTS_BY_MONTH_RESULT,
    }),
    search(currentPage) {
      this.loading = true;
      this.currentPage = currentPage;
      this.getAdjustmentsByMonth({
        month: parseInt(this.month, 10),
        year: parseInt(this.year, 10),
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage) ? currentPage : 1,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao obter os lançamentos do mês. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
